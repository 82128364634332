import React from 'react';
import { GlobalContext } from '../context/GlobalContext';

const Changelog = () => {
	const { setData } = React.useContext(GlobalContext);

	React.useEffect(() => {
		setData({ pageLoading: false });
	}, []);

	return (
		<>
			<div className="heading-wrapper">
				<div className="heading">CHANGELOG</div>
				<div className="brief">
					Read about site changes and new additions. The list is updated on each
					new version of the site.
				</div>
			</div>
			<div className="changelog">
				<div className="update">
					<div className="title">Update 12/22/2020</div>
					<div className="changes">
						<ul>
							<li>• Added small staff note when staff edits a feedback</li>
							<li>• Added option to save your email </li>
							<li>
								• Added option to delete recently written feedback (option
								available for 30 minutes only)
							</li>
							<li>• Added timestamps to feedback</li>
							<li>• Added button to Steamrep on user profiles</li>
							<li>
								• Menu automatically closes when selecting an option on mobile
							</li>
							<li>• FAQ updated</li>
							<li>
								• Fixed names breaking with specific characters (such as
								&quot;&gt;&quot;)
							</li>
							<li>• No longer possible to report yourself</li>
							<li>• Fixed user reports not asking for contact email</li>
							<li>• Fixed feedback reports</li>
							<li>• Added missing $ symbol in trade value</li>
							<li>• Centered Steam log in button on mobile</li>
							<li>
								• Fixed incorrect transaction text when trade value was not
								specified
							</li>
							<li>• Fixed multiple visual bugs on desktop and mobile</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};

export default Changelog;
