import React from 'react';
import { GlobalContext } from '../context/GlobalContext';

const Contact = () => {
	const { setData } = React.useContext(GlobalContext);

	React.useEffect(() => {
		setData({ pageLoading: false });
	}, []);

	return (
		<>
			<div className="page-heading">CONTACT</div>
			<div className="content-wrapper">
				<div className="contact">
					<div className="reason">
						<div className="title">Reason</div>
						<div className="input-wrapper">
							<div className="input">
								<select name="reason">
									<option>Ban appeal</option>
									<option>General</option>
									<option>Suggestion</option>
									<option>Business</option>
									<option>Other</option>
								</select>
							</div>
						</div>
					</div>
					<div className="email">
						<div className="title">E-mail</div>
						<div className="input-wrapper">
							<div className="input">
								<input
									placeholder="E-mail"
									name="email"
									autoComplete="off"
									type="text"
								/>
							</div>
						</div>
					</div>
					<div className="title">
						<div className="title">Title</div>
						<div className="input-wrapper">
							<div className="input">
								<input
									placeholder="Title"
									name="title"
									autoComplete="off"
									type="text"
								/>
							</div>
						</div>
					</div>
					<div className="message">
						<div className="title">Message:</div>
						<div className="message-wrapper">
							<div className="message">
								<textarea placeholder="Message" name="body"></textarea>
							</div>
						</div>
					</div>
				</div>
				<div className="send-message">
					<button className="btn btn-blue hvr-bounce-to-right disabled">
						<span className="text">Send message</span>
						<span className="icon">
							<img src="https://csgo-rep.com/icon/send.svg" />
						</span>
					</button>
				</div>
			</div>
		</>
	);
};

export default Contact;
