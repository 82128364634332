import React from 'react';
import { GlobalContext } from '../context/GlobalContext';

const Faq = () => {
	const { setData } = React.useContext(GlobalContext);

	React.useEffect(() => {
		setData({ pageLoading: false });
	}, []);

	return (
		<>
			<div className="page-heading">FAQ</div>
			<div className="faq">
				<div className="faq-item faq-item-1">
					<div className="title">INTRODUCTION</div>
					<div className="content">
						<p>
							STEAM-Rep.com is a platform created for traders by traders. Our
							objective is to provide a platform where you can safely choose who
							you trade with, as well as provide you with a way to start your
							journey in this community.
						</p>
					</div>
				</div>
				<div className="faq-item faq-item-2">
					<div className="title">WHAT IS A FEEDBACK?</div>
					<div className="content">
						<p>
							A Feedback, also known as &quot;rep&quot; or
							&quot;reputation&quot;, is essentially a rating that you can write
							on someone&apos;s profile after you&apos;ve completed a trade with
							someone (and the other user can do the same for you).{' '}
						</p>
						<p>
							If you successfully complete a trade involving something outside
							of the Steam trading window (which means someone had to send
							something first, expecting the other user to finish the
							agreement), it&apos;s important to make sure to write Feedback on
							that user&apos;s profile so that the community can check the
							user&apos;s trade history in the future and it&apos;ll help
							everyone make a decision next time they&apos;re looking to
							complete a transaction.
						</p>
					</div>
				</div>
				<div className="faq-item faq-item-3">
					<div className="title">
						WHAT DOES POSITIVE AND NEUTRAL FEEDBACK MEAN? WHY IS THERE NO
						NEGATIVE OPTION?{' '}
					</div>
					<div className="content">
						<p>
							Feedback can only have one of two ratings, positive and neutral.
						</p>
						<p>
							Positive should be used when a trade is completed successfully
							without any kind of issues, both users felt completely comfortable
							during the whole process, the user was reasonable, no sudden
							unjustified change of plans and the transaction was an overall
							great experience.
						</p>
						<p>
							Neutral should be used when a trade is completed successfully, but
							there was an issue. This can be due to a wide variety of reasons,
							the trade was started and the user disappeared for days without
							notice, the trade is halfway done and the user decides to back
							down and revert everything, or any other situation that makes you
							feel uncomfortable and have an unpleasant experience.
						</p>
						<p>
							Negative feedback does not exist in STEAM-Rep. The reason for this
							is, if someone is worthy of a negative feedback, it means the user
							scammed someone, in which case a report is filed against that user
							and the user will be banned for that action. In other words,
							negative feedback is replaced by a direct ban to the user at
							fault.{' '}
						</p>
					</div>
				</div>
				<div className="faq-item faq-item-4">
					<div className="title">HOW DO I REPORT A FAKE / SPAM FEEDBACK? </div>
					<div className="content">
						<p>
							Feedback can be reported by clicking the flag icon on the bottom
							right side of any feedback on the site, and filling the form with
							the requested information.
						</p>
					</div>
				</div>
				<div className="faq-item faq-item-5">
					<div className="title">
						IF SOMEONE HAS A LARGE AMOUNT OF POSITIVE FEEDBACK, DOES THAT MEAN
						HE WON&apos;T SCAM ME?{' '}
					</div>
					<div className="content">
						<p>No, it does not.</p>
						<p>
							While feedback is a great way to see someone&apos;s past trades
							and the overall experience other users have had with a specific
							user, it is not a guarantee that everything will go well with your
							transaction.
						</p>
						<p>
							Feedback should only be used as a way to quickly check the trades
							someone has made in the past and have a general idea of the
							experience you can expect when trading with that user.
						</p>
						<p>
							However, if someone is banned for scamming, it is strongly advised
							not to go through with any transaction with this user, as it is
							very likely that something will go wrong.
						</p>
					</div>
				</div>
				<div className="faq-item faq-item-6">
					<div className="title">WHAT IS MIDDLEMAN FEEDBACK?</div>
					<div className="content">
						<p>
							Middleman feedback is a specific type of feedback that is given
							when a person that&apos;s not the seller or buyer assists in the
							trade by holding the item / money from one user until the other
							party goes through with the transaction.
						</p>
						<p>
							Middleman feedback should only be given to the person assisting
							with the trade, not to the seller or buyer.
						</p>
					</div>
				</div>
				<div className="faq-item faq-item-7">
					<div className="title">
						CAN I WRITE FEEDBACK FOR SELLING GENUINE PINS, ARTWORK, OR SOMETHING
						ELSE OTHER THAN ITEMS / BALANCE?
					</div>
					<div className="content">
						<p>
							This site is aimed at transactions directly related to the biggest
							games with an active and large economy on Steam. For that reason,
							external things such as artwork (even if it&apos;s related to the
							game) or other kind of services should not be the reason of a
							feedback.
						</p>
						<p>
							Genuine pins are a part of the game and for that reason it is
							allowed to write feedback for the transaction of Genuine pins.
							However, the buyer of the pin must have the pin activated on his
							own inventory after the trade is complete in order for the
							feedback to be valid. This means feedback for reselling pins
							should not be here.
						</p>
					</div>
				</div>
				<div className="faq-item faq-item-8">
					<div className="title">
						WHAT KIND OF BALANCE TRANSACTION CAN I WRITE FEEDBACK ABOUT?
					</div>
					<div className="content">
						<p>
							For the time being, Buff balance transactions are the only ones
							accepted.
						</p>
						<p>
							Transactions that involve any other site or site balance, be it a
							marketplace or a gambling site, will not count as a valid
							transaction or form of payment.
						</p>
					</div>
				</div>
				<div className="faq-item faq-item-9">
					<div className="title">CAN I WRITE FEEDBACKS IN ANY LANGUAGE?</div>
					<div className="content">
						<p>Feedbacks can only be written in English.</p>
					</div>
				</div>
				<div className="faq-item faq-item-10">
					<div className="title">I AM BANNED, WHAT DO I DO?</div>
					<div className="content">
						<p>
							If you believe you were incorrectly banned,{' '}
							<a href="https://steam-reputation.com/contact">contact us</a>.
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default Faq;
