import React from 'react';
import axios from 'axios';
import { Transaction } from '../types';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';

interface ITransactionHistory {
	user?: {
		id: number;
		steam_id: string;
		spoofed_steam_id?: string;
		spoof_from?: string;
	};
}

const TransactionHistory = ({ user }: ITransactionHistory) => {
	const APIEndpoint = 'https://api.csgo-rep.com',
		[loading, setLoading] = React.useState(false),
		[order_by, setOrderBy] = React.useState([{ field: 'id', order: 'DESC' }]),
		[whereLabel, setWhereLabel] = React.useState('cross'),
		[where, setWhere] = React.useState<{
			payment_method?: any[];
			trade_type?: any[];
		} | null>(),
		[transactions, setTransactions] = React.useState<Transaction[]>([]),
		paymentMethods: { id: number; name: string; picture: string }[] = [
			{
				id: 1,
				name: 'bitcoin',
				picture: 'btc.svg',
			},
			{
				id: 2,
				name: 'ethereum',
				picture: 'eth.svg',
			},
			{
				id: 3,
				name: 'litecoin',
				picture: 'ltc.svg',
			},
			{
				id: 4,
				name: 'usdt',
				picture: 'usdt.svg',
			},
			{
				id: 5,
				name: 'PayPal',
				picture: 'paypal.svg',
			},
			{
				id: 6,
				name: 'Cash App',
				picture: 'cashapp.svg',
			},
			{
				id: 7,
				name: 'venmo',
				picture: 'venmo.svg',
			},
			{
				id: 8,
				name: 'zelle',
				picture: 'zelle.svg',
			},
			{
				id: 9,
				name: 'bank wire',
				picture: 'bank.svg',
			},
			{
				id: 10,
				name: 'cash',
				picture: 'cash.svg',
			},
			{
				id: 11,
				name: 'swish',
				picture: 'swish.svg',
			},
		],
		tradeTypes: { id: number; name: string }[] = [
			{
				id: 1,
				name: 'Sold CS:GO item',
			},
			{
				id: 2,
				name: 'Bought CS:GO item',
			},
			{
				id: 3,
				name: 'Sold balance',
			},
			{
				id: 4,
				name: 'Bought balance',
			},
		],
		fetchTransactions = ({ transactions }: { transactions: Transaction[] }) => {
			setLoading(true);
			const data = {
				id: '204',
				query: {
					filter: user?.steam_id
						? {
								to_steam_id: user.spoofed_steam_id || user.steam_id,
						  }
						: {},
					view: user ? 'creator' : 'receiver',
					offset: transactions.length,
					limit: 15,
					nulls_last: 1,
					order_by,
					where,
				},
			};

			axios.post(user?.spoof_from || APIEndpoint, data).then(res => {
				setLoading(false);
				setTransactions([...transactions, ...res.data.data]);
			});
		},
		doFilter = (
			label: string,
			where: { payment_method?: any[]; trade_type?: any[] } | null,
		) => {
			setWhereLabel(label);
			setWhere(where);
		};

	React.useEffect(() => {
		let isMounted = true;

		const _fetchTransactions = ({
			transactions,
		}: {
			transactions: Transaction[];
		}) => {
			if (isMounted) {
				setLoading(true);
			}
			const data = {
				id: '204',
				query: {
					filter: user?.steam_id
						? {
								to_steam_id: user.spoofed_steam_id || user.steam_id,
						  }
						: {},
					view: user ? 'creator' : 'receiver',
					offset: transactions.length,
					limit: 15,
					nulls_last: 1,
					order_by,
					where,
				},
			};

			axios.post(user?.spoof_from || APIEndpoint, data).then(res => {
				if (isMounted) {
					setLoading(false);
					setTransactions([...transactions, ...res.data.data]);
				}
			});
		};

		_fetchTransactions({ transactions: [] });
		return () => {
			isMounted = false;
		};
	}, [where, user, order_by]);

	return loading ? (
		<div className="subloader">
			<img src="https://csgo-rep.com/icon/loader.svg" className="animation" />
		</div>
	) : (
		<div className="list history">
			<div className="header">
				<h2>Transaction history:</h2>
				<div className="filter">
					<div className="select-box">
						<div className="header">
							<div className="title">Filter:</div>
						</div>
						<div className="buttons-wrapper">
							<div
								onClick={() => doFilter('cross', null)}
								className={
									whereLabel == 'cross' ? 'btn-select active' : 'btn-select'
								}
							>
								<img src="https://csgo-rep.com/icon/cross.svg" />
								<span></span>
							</div>
							<div
								onClick={() => doFilter('buff', { trade_type: [3, 4] })}
								className={
									whereLabel == 'buff' ? 'btn-select active' : 'btn-select'
								}
							>
								<img src="https://csgo-rep.com/icon/social/buff.svg" />
								<span></span>
							</div>
							<div
								onClick={() =>
									doFilter('btc', { payment_method: [1, 2, 3, 4] })
								}
								className={
									whereLabel == 'btc' ? 'btn-select active' : 'btn-select'
								}
							>
								<img src="https://csgo-rep.com/icon/payment/btc.svg" />
								<span></span>
							</div>
							<div
								onClick={() =>
									doFilter('cash', { payment_method: [5, 6, 7, 8, 9] })
								}
								className={
									whereLabel == 'cash' ? 'btn-select active' : 'btn-select'
								}
							>
								<img src="https://csgo-rep.com/icon/payment/cash.svg" />
								<span></span>
							</div>
						</div>
					</div>
				</div>
				<div className="sort">
					<span>Sort by:</span>
					<select
						defaultValue="id,DESC"
						onChange={e =>
							setOrderBy([
								{
									order: e.target.value.split(',')[1],
									field: e.target.value.split(',')[0],
								},
							])
						}
					>
						<option value="id,ASC">date ascending</option>
						<option value="id,DESC">date descending</option>
						<option value="amount,ASC">price ascending</option>
						<option value="amount,DESC">price descending</option>
					</select>
				</div>
			</div>
			<div className="record-content">
				{transactions?.map(t => (
					<div className="el status_1" key={t.id}>
						<div className="recommendations">
							<Link to={`/profile/${t.to_steam_id}`} className="nickname">
								{t.username}
							</Link>
							<div className="reviews-wrapper">
								<div className="icon">
									<img src="https://csgo-rep.com/icon/good_review.svg" />
								</div>
								<div className="amount">
									{t.feedback.positive > t.feedback.neutral ||
									(!t.feedback.positive && !t.feedback.neutral)
										? t.feedback.positive
										: t.feedback.neutral}
								</div>
							</div>
						</div>
						<Link
							to={`/profile/${
								user?.spoofed_steam_id || t.to_steam_id == user?.steam_id
									? t.from_steam_id
									: t.to_steam_id
							}`}
							className="avatar"
						>
							<div className="hover">View Profile</div>
							<img
								src={`https://cdn.cloudflare.steamstatic.com/steamcommunity/public/images/avatars/${t.avatar.substr(
									0,
									2,
								)}/${t.avatar}_full.jpg`}
								className={
									t.feedback.positive > t.feedback.neutral ||
									(!t.feedback.positive && !t.feedback.neutral)
										? 'positive'
										: 'neutral'
								}
							/>
						</Link>
						<div
							className={
								t.feedback.positive > t.feedback.neutral ||
								(!t.feedback.positive && !t.feedback.neutral)
									? 'status positive'
									: 'status neutral'
							}
						>
							<img
								src={
									!t.feedback.neutral
										? 'https://csgo-rep.com/icon/arrow.svg'
										: 'https://csgo-rep.com/icon/dash.svg'
								}
							/>
						</div>
						<div className="content">
							<div className="transaction-title">
								<strong>Transaction:</strong>{' '}
								{tradeTypes.find(tt => tt.id == t.trade_type)?.name}
								{t.amount && (
									<>
										{' '}
										for <span className="price-green">${t.amount}</span>
									</>
								)}
								{paymentMethods.find(p => p.id == t.payment_method)?.name && (
									<>
										{' '}
										by{' '}
										<span className="price-green">
											{paymentMethods.find(p => p.id == t.payment_method)?.name}
										</span>
									</>
								)}
							</div>
							<p>{t.body}</p>
						</div>
						<div className="icons">
							<img
								src={
									'https://csgo-rep.com/icon/payment/' +
									paymentMethods.find(p => p.id == t.payment_method)?.picture
								}
							/>
							<img
								src={
									t.trade_position == 1
										? 'https://csgo-rep.com/icon/trade_first.svg'
										: 'https://csgo-rep.com/icon/trade_second.svg'
								}
							/>
						</div>
						<div className="flag">
							<div className="comment-number">
								{DateTime.fromISO(t.created_at)
									.toFormat('f')
									.replace('/', '-')
									.replace(',', '')
									.replace(' AM', '')
									.replace(' PM', '')}{' '}
								| #{t.id}
							</div>
						</div>
					</div>
				))}
				<div className="summary">
					<button
						className="btn"
						onClick={() => fetchTransactions({ transactions })}
					>
						Load more
					</button>
				</div>
			</div>
		</div>
	);
};

export default TransactionHistory;

// <div class="loader"><img src="/logo.svg" class="logo"><img src="/icon/loader.svg" class="animation"><span>Loading...</span></div>
