import React from 'react';
import axios from 'axios';
import TransactionHistory from '../components/transaction-history';
import { GlobalContext } from '../context/GlobalContext';

interface IProfile {
	match: any;
}

interface IUser {
	id: number;
	steam_id: string;
	role_id: number;
	username: string;
	avatar: string;
	link_twitch: string;
	link_twitter: string;
	link_bitskins: string;
	link_buff: string;
	feedback: {
		positive: number;
		neutral: number;
		cash: number;
		crypto: number;
		balance: number;
	};
	custom_photo?: string;
	hide_steamrep?: boolean;
}

const Profile = ({ match }: IProfile) => {
	const APIEndpoint = 'https://api.csgo-rep.com/',
		CustomAPIEndpoint = `${window.location.origin}/profiles.json`,
		[user, setUser] = React.useState<IUser>(),
		[fetchUserError, setFetchUserError] = React.useState<null | Error>(null),
		[loading, setLoading] = React.useState(true),
		{ setData } = React.useContext(GlobalContext);
	const merge = (target: any, source: any) => {
		// Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
		for (const key of Object.keys(source)) {
			if (source[key] instanceof Object && key in target)
				Object.assign(source[key], merge(target[key], source[key]));
		}

		// Join `target` and modified `source`
		Object.assign(target || {}, source);
		return target;
	};

	React.useEffect(() => {
		let isMounted = true;
		setData({
			footerHidden: !fetchUserError && !user,
			pageLoading: loading ? true : !fetchUserError && !user,
		});
		return () => {
			// eslint-disable-next-line
			isMounted = false;
		};
	}, [user, loading, fetchUserError]);

	React.useEffect(() => {
		let isMounted = true;
		async function fetchUserProfile() {
			try {
				const spoofedProfiles = await axios.get(CustomAPIEndpoint);
				const spoofedProfile: IUser[] = spoofedProfiles.data.find(
					(v: any) => v.profile.steam_id == match?.params?.user_id,
				);

				const res = await axios.post(APIEndpoint, {
					id: '206',
					query: { steam_id: match.params.user_id },
				});

				if (spoofedProfile && isMounted) {
					// if (sp.spoof_from !== APIEndpoint) {
					// 	const fakeRes = await axios.post(sp.spoof_from, {
					// 		id: '204',
					// 		query: { steam_id: match.params.user_id },
					// 	});
					// 	res = merge(res, fakeRes);
					// }
					return setUser(merge(res.data, spoofedProfile).profile);
				}

				if (isMounted) {
					if (!res.data.profile) {
						throw Error('User not found');
					}

					setUser(res.data.profile);
				}
			} catch (e: any) {
				if (isMounted) {
					setFetchUserError(e as Error);
				}
			} finally {
				if (isMounted) {
					setLoading(false);
				}
			}
		}
		fetchUserProfile();
		return () => {
			isMounted = false;
		};
	}, [match.params.user_id]);

	return !fetchUserError && user ? (
		<div>
			<div className="profile-header">
				<div className="header">
					<div className="stats-wrapper">
						<div className="rating">
							<div className="circle green">
								<div className="icon">
									<img src="https://csgo-rep.com/icon/arrow.svg" />
								</div>
								<span>{user?.feedback?.positive}</span>
							</div>
							<div className="circle yellow">
								<div className="icon">
									<img src="https://csgo-rep.com/icon/dash.svg" />
								</div>
								<span>{user?.feedback?.neutral}</span>
							</div>
						</div>
						<div className="avatar-placehold">
							<div className="inner positive">
								<img
									src={
										user?.custom_photo ||
										`https://cdn.cloudflare.steamstatic.com/steamcommunity/public/images/avatars/${user?.avatar?.substr(
											0,
											2,
										)}/${user?.avatar}_full.jpg`
									}
									className="avatar"
								/>
							</div>
						</div>
					</div>
					<div className="info-wrapper">
						<h1 className="nickname">{user?.username}</h1>
						<div className="profile-link">
							<input
								readOnly
								value={`https://steamcommunity.com/profiles/${user?.steam_id}/`}
							/>
							<button
								onClick={() => {
									navigator.clipboard
										.writeText(
											`https://steamcommunity.com/profiles/${user?.steam_id}/`,
										)
										.then(() => {
											const notifier = document.querySelector('#notifier');
											if (notifier) {
												notifier.innerHTML =
													// eslint-disable-next-line
													'<div class=\'el info\'><div class=\'left\'></div><div class=\'right\'><div class=\'title\'>Link copied</div></div></div>';
												setTimeout(() => {
													notifier.innerHTML = '';
												}, 5e3);
											}
										});
								}}
							>
								<img src="https://csgo-rep.com/icon/copy.svg" />
							</button>
						</div>
						<div className="profile-link">
							<input
								readOnly
								value={`https://steam-reputation.com/profile/${user?.steam_id}`}
							/>
							<button
								onClick={() => {
									navigator.clipboard
										.writeText(
											`https://steam-reputation.com/profile/${user?.steam_id}`,
										)
										.then(() => {
											const notifier = document.querySelector('#notifier');
											if (notifier) {
												notifier.innerHTML =
													// eslint-disable-next-line
													"<div class='el info'><div class='left'></div><div class='right'><div class='title'>Link copied</div></div></div>";
												setTimeout(() => {
													notifier.innerHTML = '';
												}, 5e3);
											}
										});
								}}
							>
								<img src="https://csgo-rep.com/icon/copy.svg" />
							</button>
						</div>
						<div className="info">
							<div className="el">
								<img src="https://csgo-rep.com/icon/handshake.svg" />
								<strong>Done deals:</strong>
								<span>{user?.feedback?.positive}</span>
							</div>
						</div>
						<div className="linked-accounts">
							<span>Linked accounts:</span>
							<a
								href={`https://steamcommunity.com/profiles/${user?.steam_id}/`}
								rel="nofollow noopener noreferrer"
								target="_blank"
							>
								<div className="social steam">
									<img src="https://csgo-rep.com/icon/social/steam.svg" />
								</div>
							</a>
							{!user?.hide_steamrep && (
								<a
									href={`http://steamrep.com/search?q=${user?.steam_id}`}
									rel="nofollow noopener noreferrer"
									target="_blank"
								>
									<div className="social no-filter">
										<img src="https://csgo-rep.com/icon/social/steamrep.svg" />
									</div>
								</a>
							)}
						</div>
					</div>
				</div>
			</div>

			<div className="summary">
				<div className="summary-wrapper">
					<div className="el">
						<div className="social paypal">
							<img src="https://csgo-rep.com/icon/payment/usdt.svg" />
						</div>
						<strong>Cash deals:</strong>
						<span>{user?.feedback?.cash}</span>
					</div>
					<div className="el">
						<div className="social bitcoin">
							<img src="https://csgo-rep.com/icon/payment/btc.svg" />
						</div>
						<strong>Crypto deals:</strong>
						<span>{user?.feedback?.crypto}</span>
					</div>
					<div className="el">
						<div className="social buff">
							<img src="https://csgo-rep.com/icon/social/buff.svg" />
						</div>
						<strong>Balance deals:</strong>
						<span>{user?.feedback?.balance}</span>
					</div>
				</div>
			</div>

			{user && <TransactionHistory user={user} />}
		</div>
	) : (
		<div className="not_found">Profile not found :(</div>
	);
};

export default Profile;
