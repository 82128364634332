import React from 'react';
import { NavLink } from 'react-router-dom';
import login from '../assets/img/rasta/login.png';

const Header = () => {
	const [mobileVisible, setMobileVisible] = React.useState(false);

	return (
		<header className={mobileVisible ? 'mobile-visible' : ''}>
			<a
				href="/"
				id="logo"
				className="router-link-active router-link-exact-active"
				aria-current="page"
			></a>
			<nav className={mobileVisible ? 'mobile-visible' : ''}>
				<NavLink
					exact
					to="/"
					activeClassName="router-link-active router-link-exact-active active"
				>
					Browse
				</NavLink>
				<NavLink
					exact
					to="/faq"
					activeClassName="router-link-active router-link-exact-active active"
				>
					FAQ
				</NavLink>
				<NavLink
					exact
					to="/contact"
					activeClassName="router-link-active router-link-exact-active active"
				>
					Contact
				</NavLink>
				<div className="">
					<img src={login} className="steam-login" />
				</div>
			</nav>
			<div className="hamburger-wrapper">
				<button
					className={
						mobileVisible
							? 'is-active hamburger hamburger--spin'
							: 'hamburger hamburger--spin'
					}
					type="button"
					onClick={() => setMobileVisible(!mobileVisible)}
				>
					<span className="hamburger-box">
						<span className="hamburger-inner"></span>
					</span>
				</button>
			</div>
		</header>
	);
};

export default Header;
