import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

function ScrollToTop() {
	const { pathname } = useLocation();
	const history = useHistory();

	React.useEffect(() => {
		document.querySelector('.ps.ps--active-y')?.scrollTo(0, 0);
	}, [pathname, history]);

	return null;
}

export default ScrollToTop;
