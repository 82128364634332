import React from 'react';

interface IGlobalContext {
	data: Record<string, any>;
	setData: (prop: Record<string, any>) => any;
}

// Create the context
export const GlobalContext = React.createContext<IGlobalContext>({
	data: {
		pageLoading: true,
	},
	setData: () => null,
});

// Create a provider component to wrap the entire app and provide the global state
export const GlobalProvider = ({ children }: { children: any }) => {
	const [_data, _setData] = React.useState({});

	const setData = (data: Record<string, any>) => {
		_setData({ ..._data, ...data });
	};

	return (
		<GlobalContext.Provider
			value={{
				data: _data,
				setData,
			}}
		>
			{children}
		</GlobalContext.Provider>
	);
};
