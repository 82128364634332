import React from 'react';
import TransactionHistory from '../components/transaction-history';
import { GlobalContext } from '../context/GlobalContext';

interface IHome {
	history: any;
}

const Home = ({ history }: IHome) => {
	const [inputError, setInputError] = React.useState(''),
		{ setData } = React.useContext(GlobalContext),
		[searchInput, setSearchInput] = React.useState(),
		handleChange = (e: any) => {
			setSearchInput(e.target.value);
			if (e.target.value.length && e.target.value.length < 17) {
				setInputError('Minimum characters: 17');
			} else if (e.target.value.length && e.target.value.length > 17) {
				setInputError('Maximium characters: 17');
			} else if (!/^7656119\d{10}$/.test(e.target.value)) {
				setInputError(' ');
			} else {
				setInputError('');
			}
		},
		onKeyUp = (e: any) => {
			if (!inputError && searchInput && e.keyCode === 13) {
				history.push(`/profile/${searchInput}`);
			} else if (!searchInput) {
				setInputError('This field is required');
			}
		};

	React.useEffect(() => {
		setData({ pageLoading: false });
	}, []);

	return (
		<>
			<div className="search">
				<div className="heading">Search user</div>
				<div className={inputError ? 'input-wrapper error' : 'input-wrapper'}>
					{inputError && (
						<span className="error-text">
							<img src="https://csgo-rep.com/icon/warning-red.svg" />
							{inputError}
						</span>
					)}
					<div className="input">
						<input
							onKeyUp={onKeyUp}
							onInput={handleChange}
							placeholder="SteamID64"
							name="steam_id"
							autoComplete="off"
							type="text"
						/>
						<div
							className="btn-search"
							onClick={() => history.push(`/profile/${searchInput}`)}
						>
							<img src="https://csgo-rep.com/icon/magnifier.svg" />
						</div>
					</div>
				</div>
				<div className="description">
					<span>Enter SteamID64 here</span>
					<div className="icon icon-arrow">
						<img src="https://csgo-rep.com/icon/curve_arrow.svg" />
					</div>
				</div>
			</div>

			<TransactionHistory />
		</>
	);
};

export default Home;
