import React from 'react';
import { NavLink } from 'react-router-dom';
import bs3 from '../assets/img/rasta/bs-3.png';

const Foot = ({
	userStats,
}: {
	userStats: {
		users: number;
		feedbacks: number;
		bans: number;
	};
}) => {
	return (
		<>
			<div className="advertisement">
				<div className="content">
					<div className="banner-wrapper">
						<a href="#" target="_blank" rel="noreferrer">
							<img className="banner" src={bs3} />
						</a>
					</div>
				</div>
			</div>
			<div className="statistics">
				<div className="statistics__wrapper">
					<div className="stats-box positive_feedbacks">
						<div className="status green">
							<img src="https://csgo-rep.com/icon/arrow.svg" />
						</div>
						<div className="stats">
							<div className="number">{userStats?.feedbacks}</div>
							<div className="title">POSITIVE FEEDBACKS</div>
						</div>
					</div>
					<div className="stats-box banned_users">
						<div className="status red">
							<img src="https://csgo-rep.com/icon/cross.svg" />
						</div>
						<div className="stats">
							<div className="number">{userStats?.bans}</div>
							<div className="title">BANNED USERS</div>
						</div>
					</div>
					<div className="stats-box users_registered">
						<div className="status blue">
							<img src="https://csgo-rep.com/icon/user.svg" />
						</div>
						<div className="stats">
							<div className="number">{userStats?.users}</div>
							<div className="title">USERS REGISTERED</div>
						</div>
					</div>
				</div>
			</div>
			<div className="nav-wrapper">
				<div className="additional-info">
					<img src="/logo.svg" />
					<div className="text">
						<p>Website to keep track of your cash trades, find trustworthy</p>
						<p>users to do transactions with and avoid risky trades</p>
						<p>by easily checking someone&apos;s transaction history.</p>
						<p>Founded in 2020.</p>
					</div>
				</div>
				<div className="nav">
					<div className="title">Menu STEAMREPUTATION</div>
					<div className="buttons">
						<NavLink
							exact
							to="/"
							activeClassName="router-link-active router-link-exact-active"
						>
							<div className="nav-btn">
								<img src="https://csgo-rep.com/icon/li.svg" /> Browse
							</div>
						</NavLink>
						<NavLink
							to="/faq"
							activeClassName="router-link-active router-link-exact-active"
						>
							<div className="nav-btn">
								<img src="https://csgo-rep.com/icon/li.svg" /> FAQ
							</div>
						</NavLink>
						<NavLink
							to="/changelog"
							activeClassName="router-link-active router-link-exact-active"
						>
							<div className="nav-btn">
								<img src="https://csgo-rep.com/icon/li.svg" /> Changelog
							</div>
						</NavLink>
						<NavLink
							to="/contact"
							activeClassName="router-link-active router-link-exact-active"
						>
							<div className="nav-btn">
								<img src="https://csgo-rep.com/icon/li.svg" /> Contact
							</div>
						</NavLink>
					</div>
				</div>
				<div className="bg">
					<img src="https://csgo-rep.com/weapons.png" />
				</div>
			</div>
			<footer>
				<div className="left">
					<div className="statement">
						<span className="version">v1.12</span>STEAMrep 2020. Powered by
						Steam. Not affiliated with Valve Corp.
					</div>
				</div>
				{/* <div className="right">
					<a
						href="https://twitter.com/STEAMRepCom"
						target="_blank"
						className="twitter"
						rel="noreferrer"
					>
						<div className="icon social-icon">
							<img src="https://csgo-rep.com/icon/social/twitter_footer.svg" />
						</div>
						@STEAMRepCom
					</a>
				</div> */}
			</footer>
		</>
	);
};

export default Foot;
