import React from 'react';
import axios from 'axios';
import { Switch, Route, BrowserRouter, useLocation } from 'react-router-dom';
import Header from './entails/header';
import Foot from './entails/foot';
import Home from './pages/home';
import Contact from './pages/contact';
import Faq from './pages/faq';
import Profile from './pages/profile';
import Changelog from './pages/changelog';
import { GlobalContext, GlobalProvider } from './context/GlobalContext';
import ScrollToTop from './components/scroll-to-top';

const App = () => {
	const APIEndpoint = 'https://api.csgo-rep.com',
		[loading, setLoading] = React.useState(false),
		[userStats, setUserStats] = React.useState<{
			users: number;
			feedbacks: number;
			bans: number;
		}>(),
		fetchUserStats = () => {
			setLoading(true);
			const data = {
				id: 209,
				query: {},
			};

			axios.post(APIEndpoint, data).then(res => {
				setUserStats(res.data);
				setLoading(false);
			});
		},
		Router = () => {
			const [page, setPage] = React.useState('browse'),
				{ data } = React.useContext(GlobalContext),
				location = useLocation();

			React.useEffect(() => {
				if (window.location.pathname == '/') {
					setPage('browse');
				} else {
					const splitArr = window.location.pathname.split('/');
					const split = splitArr[splitArr.length - 1] ?? 'browse';
					setPage(split);
				}
			}, [location]);

			return userStats ? (
				<div id={`subpage-${page}`} className="subpage">
					<Switch>
						<Route exact path="/profile/:user_id" component={Profile} />
						<Route exact path="/changelog" component={Changelog} />
						<Route exact path="/contact" component={Contact} />
						<Route exact path="/faq" component={Faq} />
						<Route exact path="/" component={Home} />
						<Route component={Home} />
					</Switch>
					{!data.footerHidden && <Foot userStats={userStats} />}
				</div>
			) : null;
		},
		Loader = ({ loading }: { loading: boolean }) => {
			const { data } = React.useContext(GlobalContext);

			return (
				<>
					{(loading || data.pageLoading) && (
						<div className="loader">
							<img src="/logo.svg" className="logo" />
							<img
								src="https://csgo-rep.com/icon/loader.svg"
								className="animation"
							/>
							<span>Loading...</span>
						</div>
					)}
				</>
			);
		};

	React.useEffect(() => {
		fetchUserStats();
	}, []);

	return (
		<GlobalProvider>
			<BrowserRouter>
				<div id="popup"></div>
				<Loader loading={loading} />
				<div id="notifier"></div>
				<Header />
				<main>
					<div className="ps ps--active-y">
						<ScrollToTop />
						<Router />
					</div>
				</main>
			</BrowserRouter>
		</GlobalProvider>
	);
};

export default App;
